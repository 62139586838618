module.exports = [{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":false,"showCaptions":true,"quality":90},
    },{
      plugin: require('../../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"/opt/build/repo/gatsby-theme-minsbi/src/themes/typography.js"},
    },{
      plugin: require('../../gatsby-theme-minsbi/gatsby-browser.js'),
      options: {"plugins":[],"siteName":"Example Site","showIntro":false},
    }]
